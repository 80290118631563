import { Col, Form, Input, Row, Spin, Typography } from "antd";
import FormItem from "antd/es/form/FormItem";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { addDoctorListInProgress } from "../../../appRedux/admin/Doctor/actions/Doctors";

import ButtonComponent from "../../../components/Button/ButtonComponent";
import FormInput from "../../../components/Input/FormInput";

import Auxiliary from "../../../util/Auxiliary";
import IntlMessages from "../../../util/IntlMessages";

import CitySelector from "./CitySelector";
import CountrySelector from "./CountrySelector";
import DesignationSelector from "./DesignationSelector";
import OrganizationSelector from "./OrganizationSelector";
import QualificationSelector from "./QualificationSelector";
import SpecializationSelector from "./SpecializationSelector";
import StateSelector from "./StateSelector";
import moment from "moment";
import { APP_ROUTES } from "../../../constants/Routes";
import { Select } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getRolesListInProgress } from "../../../appRedux/admin/roles/action/rolesAction";
import FormSelectBox from "../../../components/SelectBox/SelectBox";
import { doctorMode, status } from "../../../constants/Patient";
const { Option } = Select;

const DoctorForm = () => {
  const organization_id = localStorage.getItem("org_id");
  const [loading, setLoading] = useState(false);
  const [dob, setDob] = useState("");
  const [form] = Form.useForm();
  const [image, setImage] = useState("");
  const [photo123, setphoto123] = useState(null);
  const [country, setCountry] = useState("");
  const selectedCountryID = useRef("");
  const [state, setState] = useState("");
  const selectedStateID = useRef("");
  const [city, setCity] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const Option = Select.Option;

  function encodeImageFileAsURL(e) {
    var file = e.target.files[0];
    setphoto123(URL.createObjectURL(e.target.files[0]));
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      var base64 = reader.result.replace(/^data:image\/\w+;base64,/, "");
      setImage(base64);
    };
  }

  const doctorData = useSelector((state) => state.doctor);
  const doctorSuccess = doctorData?.addlist;
  const { accessRoles } = useSelector((state) => state);
  const roles =
    accessRoles?.rolesList?.data?.data?.filter(
      (item) => item.role_id === "2"
    ) || [];
  useEffect(() => {
    form.setFieldsValue({ organization_id: organization_id });
  }, [organization_id]);

  useEffect(() => {
    if (doctorSuccess?.success) {
      setphoto123(null);

      setTimeout(() => {
        form.resetFields();
      }, 4000);
    }
  }, [doctorSuccess?.success]);

  const onChangeCountry = (value) => {
    setCountry(value);
  };
  const onChangeCity = (value) => {
    setCity(value);
  };
  const onChangeState = (value) => {
    setState(value);
  };
  useEffect(() => {
    selectedCountryID.current = country;
  }, [country]);
  useEffect(() => {
    selectedStateID.current = state;
  }, [state]);
  const dispatch = useDispatch();
  const history = useHistory();
  const onFinish = (datas) => {
    const handleSuccess = () => {
      history.push(APP_ROUTES.DOCTOR);
    };
    let formData = new URLSearchParams();
    formData.append("firstName", datas.firstName);
    formData.append("lastName", datas.lastName);
    formData.append("dob", datas.dob);
    formData.append("email", datas.email);
    formData.append("contact", datas.contact);
    formData.append("address1", datas.address1);
    formData.append("address2", datas.address2);
    formData.append("city", datas.city);
    formData.append("state", datas.state);
    formData.append("country_code", datas.country_code);
    formData.append("qualification", datas.qualification);
    formData.append("license_no", datas.license_no);
    formData.append("organization_id", datas.organization_id);
    formData.append("specialization_id", datas.specialization_id);
    formData.append("designation_id", datas.designation_id);
    formData.append("profile_image", image);
    formData.append("gender_type", datas.gender_type);
    formData.append("access_role_id", selectedRole);
    dispatch(addDoctorListInProgress({ formData, handleSuccess }));
  };
  useEffect(() => {
    if (doctorSuccess?.progressing) {
      setLoading(true);
    } else if (doctorSuccess?.success) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [doctorSuccess?.progressing, doctorSuccess?.success]);
  useEffect(() => {
    if (doctorSuccess?.success) {
      history.push("/doctor");
    }
  }, [doctorSuccess?.success]);
  const handleDobChange = (event) => {
    const newDob = event.target.value;
    setDob(newDob);
  };

  const validateDob = () => {
    const currentDate = moment();
    const enteredDate = moment(dob);
    const age = currentDate.diff(enteredDate, "years");

    const minAgeForMinor = 18; // Adjust as needed

    return age < minAgeForMinor;
  };
  useEffect(() => {
    const paylod = {
      pagination_required: "false",
      pageNo: 0,
      pagesize: 0,
    };

    dispatch(getRolesListInProgress(paylod));
  }, []);
  const genderOptions = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
    { value: "O", label: "Other" },
  ];

  return (
    <Spin size="large" spinning={loading} tip="Saving...">
      <Auxiliary>
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Row>
            <Col className="img">
              <img className="img-btn" src={photo123} />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem
                label="Profile"
                rules={[
                  {
                    required: true,
                    message: "Please upload your image!",
                  },
                ]}
                name="profile_image"
                valuePropName="fileList"
              >
                <Input
                  type={"file"}
                  accept="image/*"
                  name="profile_image"
                  onChange={(e) => encodeImageFileAsURL(e)}
                />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="First Name"
                name="firstName"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.doctor.label.firstname"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.firstname"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="Last Name"
                name="lastName"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.doctor.label.lastname"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.lastname"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="date"
                placeholder="DOB"
                name="dob"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.doctor.label.dob"} />}
                onChange={handleDobChange}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.dob"} />
                    ),
                  },
                  {
                    validator: validateDob(),
                    message: "Please enter correct age",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                placeholder="Email"
                name="email"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.doctor.label.email"} />}
                rules={[
                  {
                    type: "email",
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.emailtype"} />
                    ),
                  },
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.email"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                placeholder="Contact"
                name="contact"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.doctor.label.contact"} />}
                rules={[
                  {
                    type: "contact",
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.contact"} />
                    ),
                  },
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.contact"} />
                    ),
                  },
                  {
                    pattern: /^[0-9]{10}$/,
                    message: "Please enter a valid 10-digit phone number",
                  },
                ]}
              />
            </Col>{" "}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                placeholder="Address 1"
                name="address1"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.doctor.label.address1"} />}
                rules={[
                  {
                    type: "text",
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.address1"} />
                    ),
                  },
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.address1"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                placeholder="Address 2"
                name="address2"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.doctor.label.address2"} />}
                rules={[
                  {
                    type: "text",
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.address2"} />
                    ),
                  },
                  {
                    required: false,
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.address2"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CountrySelector
                label={"Country Name"}
                name={"country_code"}
                onChange={onChangeCountry}
                country={country}
                rules={[
                  {
                    required: true,
                    message: "Please select your country!",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <StateSelector
                label={"State"}
                name={"state"}
                onChange={onChangeState}
                country={state}
                countryId={country?.id}
                rules={[
                  {
                    required: true,
                    message: "Please select your state!",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CitySelector
                label={"City"}
                name={"city"}
                onChange={onChangeCity}
                country={city}
                StateId={state?.id}
                rules={[
                  {
                    required: true,
                    message: "Please select your city!",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <QualificationSelector
                margin="mb-30"
                label={"Qualification"}
                name={"qualification"}
                placeholder={"Select  Qualification"}
                editMode={true}
                rules={[
                  {
                    required: true,
                    message: "Please select your qualification",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                placeholder="License No"
                name="license_no"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.doctor.label.license_no"} />}
                rules={[
                  {
                    type: "text",
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.license_no"} />
                    ),
                  },
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.license_no"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <OrganizationSelector
                margin="mb-30"
                label={"Organization"}
                name={"organization_id"}
                placeholder={"Select  Organization"}
                editMode={false}
                rules={[
                  {
                    required: true,
                    message: "Please select your organization",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <SpecializationSelector
                margin="mb-30"
                label={"Specialization"}
                name={"specialization_id"}
                placeholder={"Select  Specialization"}
                editMode={true}
                rules={[
                  {
                    required: true,
                    message: "Please select your specialization",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <DesignationSelector
                margin="mb-30"
                label={"Designation"}
                name={"designation_id"}
                placeholder={"Select Your Designation"}
                editMode={true}
                rules={[
                  {
                    required: true,
                    message: "Please select your designation",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Gender"
                name="gender_type"
                rules={[
                  {
                    required: true,
                    message: "Please select gender",
                  },
                ]}
              >
                <Select placeholder="Select gender">
                  {genderOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Typography style={{ margin: "10px 0px 6px 0px" }}>
                * Role
              </Typography>
              <Form.Item
                name="access_role_id"
                dis
                rules={[
                  {
                    required: true,
                    message: "Please select a role",
                  },
                ]}
              >
                <Select
                  placeholder="Select Role"
                  onChange={(value) => setSelectedRole(value)}
                  value={selectedRole}
                  style={{ width: "100%" }}
                >
                  {roles.map((role) => (
                    <Select.Option key={role.id} value={role.id}>
                      {role.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                label={<IntlMessages id={"Addnew.admin.label.DOCTOR.TYPE"} />}
                name={"doctor_mode"}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.admin.validator.Doctormode"} />
                    ),
                  },
                ]}
              >
                <FormSelectBox
                  placeholder="Select Doctor mode"
                  // value={frontendValue}
                  options={doctorMode || []}
                  onChange={() => {}}
                />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                label={<IntlMessages id={"Addnew.admin.label.DOCTOR.STATUS"} />}
                name={"status"}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.admin.validator.Status"} />
                    ),
                  },
                ]}
              >
                <FormSelectBox
                  placeholder="Select Doctor status"
                  // value={frontendValue}
                  options={status || []}
                  onChange={() => {}}
                />
              </Form.Item>
            </Col>
          </Row>
          <FormInput name="user_id" type="hidden" />
          <Row className="space">
            <Col className="btn-pos">
              <Link to={"/doctor"}>
                <ButtonComponent
                  className={"secondary-color mr-20"}
                  label="Cancel"
                />
              </Link>{" "}
              <ButtonComponent
                type="primary"
                htmlType="submit"
                label="Submit"
              />
            </Col>
          </Row>
        </Form>
      </Auxiliary>
    </Spin>
  );
};

export default DoctorForm;
